import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { handleDjangoDataRequest } from "../../utils/apiUtils";
import { useState } from "react";
import { toRegulerCase } from "../../utils/Utils";
import { Edit } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import EditSubscriptions from "./EditSubscriptions";
import AddSubscriptions from "./AddSubscriptions";
import { toast } from "react-toastify";

export default function Subscriptions({ hotelId }) {
  const basic = [
    "name",
    "amount",
    "sub_startdate",
    "sub_enddate",
    "inactive_date",
    "property_status",
    "inactive_reason",
  ];
  const commissions = [
    "perc_direct",
    "perc_onlinedirect",
    "perc_onlinemarket",
    "perc_extota",
    "pss_mode",
    "pss_manual_commission",
    "pss_automatic_commission",
    "invoice_payment_commission",
    "payment_link_commission",
    "room_night_fee",
    "self_service_scin_commission",
    "self_service_ecin_commission",
    "self_service_lcout_commission",
    "self_service_pos_commission",
    "self_service_room_upgrade_commission",
  ];
  const others = [
    "property_contact",
    "property_status_log",
    "deal_owner",
    "is_digitalfranchise",
    "pss_comments",
    "booking_sms_notif",
    "booking_whatsapp_notif",
    "enable_id_scanner",
    "enable_gha",
    "enable_bookflexi",
    "pg_account_type",
    "pg_transfer_on",
  ];
  const [refresh, setRefresh] = useState();
  const [subscriptions, setSubscriptions] = useState();
  useEffect(() => {
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaAdminListHotelSubscriptions/?hotel_id=${hotelId}`
    )
      .then((data) => {
        console.log(data);
        data.subscriptions
          ? setSubscriptions(data.subscriptions[0])
          : setSubscriptions({});
        console.log(Object.keys(data.subscriptions[0]));
      })
      .catch((err) => console.log(err));
  }, [refresh]);
  //------------------Modal Delete------------------------------
  const delstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "8px",
    borderRadius: "4px",
  };
  const [delopen, setDelOpen] = useState(false);
  const handleDelClose = () => {
    setDelOpen(false);
  };
  const handleDelOpen = () => {
    setDelOpen(true);
  };

  const handleDeleteSubscription = () => {
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaAdminDeleteHotelSubscription/?id=${subscriptions?.id}&hotel_id=${hotelId}`
    )
      .then((data) => {
        toast.success("Subscription Deleted");
        setRefresh(new Date());
        handleDelClose();
      })
      .catch((err) => toast.error("Something went wrong"));
  };
  //---------------Modal Edit and Add-------------------
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1400,
    height: "100%",
    maxHeight: 760,
    bgcolor: "background.paper",
    boxShadow: 24,
    overflow: "scroll",
    borderRadius: "4px",
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  //------------handle Modal----------------
  const [action, setAction] = useState();
  const handleAddSubscription = () => {
    setAction("add");
    handleOpen();
  };
  const handleEditSubscription = () => {
    setAction("edit");
    handleOpen();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Button
            type="submit"
            disabled={subscriptions}
            variant="custom-button"
            sx={{ mb: 1 }}
            onClick={handleAddSubscription}
          >
            Add Subscriptions
          </Button>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={4}>
          <Box>
            <Button
              disabled={!subscriptions}
              onClick={handleEditSubscription}
              variant="outlined"
            >
              Edit Subscriptions <Edit />
            </Button>
            <Button
              sx={{ ml: 2 }}
              disabled={!subscriptions}
              onClick={handleDelOpen}
              variant="outlined"
              color="error"
            >
              Delete Subscriptions <DeleteForeverIcon />
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Card sx={{ mb: 2 }}>
          <CardHeader
            title={"Basic information"}
            // subheader={
            //   "Used for charging swiped cards or manually added cards in Folio"
            // }
          ></CardHeader>
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              {subscriptions &&
                basic?.map((elm) => (
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        {toRegulerCase(elm)}
                      </Typography>
                      <Typography>{subscriptions[elm]}</Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardHeader
            title={"Commissions"}
            // subheader={
            //   "Used for charging swiped cards or manually added cards in Folio"
            // }
          ></CardHeader>
          <Divider />
          <CardContent>
            {subscriptions && (
              <Grid container spacing={1}>
                <>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Walk-In Commission %
                      </Typography>
                      <Typography>{subscriptions["perc_direct"]}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Booking Engine Commission %
                      </Typography>
                      <Typography>
                        {subscriptions["perc_onlinedirect"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Direct OTA and GHA Commission %
                      </Typography>
                      <Typography>
                        {subscriptions["perc_onlinemarket"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        External OTA Commission %
                      </Typography>
                      <Typography>{subscriptions["perc_extota"]}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Flex Collect Mode
                      </Typography>
                      <Typography>{subscriptions["pss_mode"]}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        FlexCollect Manual Commission %
                      </Typography>
                      <Typography>
                        {subscriptions["pss_manual_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        FlexCollect Auto Commission %
                      </Typography>
                      <Typography>
                        {subscriptions["pss_automatic_commission"]}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Invoice Payment Commission %
                      </Typography>
                      <Typography>
                        {subscriptions["invoice_payment_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Payment Link Commission %
                      </Typography>
                      <Typography>
                        {subscriptions["payment_link_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Room night fee
                      </Typography>
                      <Typography>{subscriptions["room_night_fee"]}</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Magiclink Selfcheckin Commission%
                      </Typography>
                      <Typography>
                        {subscriptions["self_service_scin_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Magiclink Earlycheckin Commission%
                      </Typography>
                      <Typography>
                        {subscriptions["self_service_ecin_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Magiclink Latecheckout Commission%
                      </Typography>
                      <Typography>
                        {subscriptions["self_service_lcout_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Magiclink POS Commission%
                      </Typography>
                      <Typography>
                        {subscriptions["self_service_pos_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Magiclink Selfupgrade Commission%
                      </Typography>
                      <Typography>
                        {subscriptions["self_service_room_upgrade_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        Mobile App QR Code Commission%
                      </Typography>
                      <Typography>
                        {subscriptions["mobile_app_qr_code_commission"]}
                      </Typography>
                    </Box>
                  </Grid>
                </>

                {/* {subscriptions &&
                commissions?.map((elm) => (
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography variant="subtitle2">
                        {toRegulerCase(elm)}
                      </Typography>
                      <Typography>{subscriptions[elm]}</Typography>
                    </Box>
                  </Grid>
                ))} */}
              </Grid>
            )}
          </CardContent>
        </Card>
        <Card sx={{ mb: 2 }}>
          <CardHeader
            title={"Others"}
            // subheader={
            //   "Used for charging swiped cards or manually added cards in Folio"
            // }
          ></CardHeader>
          <Divider />
          <CardContent>
            <Grid container spacing={1}>
              {subscriptions &&
                others?.map((elm) => (
                  <Grid item xs={4}>
                    <Box
                      sx={{
                        mb: 2,
                        border: "1px solid #D3D3D3",
                        borderRadius: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        p: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: 700, color: "gray" }}
                      >
                        {toRegulerCase(elm)}
                      </Typography>
                      <Typography>{subscriptions[elm]}</Typography>
                    </Box>
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>

      <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box sx={{ width: 1000, padding: 2 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>

          <Divider sx={{ marginY: 2 }} />

          {action === "add" && (
            <AddSubscriptions
              handleClose={handleClose}
              hotelId={hotelId}
              setRefresh={setRefresh}
            />
          )}
          {action === "edit" && (
            <EditSubscriptions
              handleClose={handleClose}
              subscriptions={subscriptions}
              setRefresh={setRefresh}
              hotelId={hotelId}
              subscriptionsId={subscriptions?.id}
            />
          )}
        </Box>
      </Drawer>
      {/* ----------------------------------- */}
      <Modal
        open={delopen}
        onClose={handleDelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={delstyle}>
          <CloseIcon
            fontSize="small"
            sx={{ position: "absolute", top: 6, right: 6, cursor: "pointer" }}
            onClick={handleDelClose}
          />

          <Divider sx={{ mt: 4 }} />
          <Typography>
            You are about to delete subscription . Do you want to proceed?
          </Typography>
          <Button
            color="error"
            variant="outlined"
            sx={{ m: 1 }}
            onClick={handleDelClose}
          >
            No
          </Button>
          <Button
            variant="custom-button"
            sx={{ m: 1 }}
            onClick={handleDeleteSubscription}
          >
            Yes
          </Button>
        </Box>
      </Modal>
    </Box>
  );
}
