import React, { useEffect, useState } from "react";

import Table from "../../components/Table";
import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,

  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,

} from "@mui/material";
import {
  handleDjangoDataMutationRequest,
  handleDjangoDataRequest,
  handleSpringDataMutationRequest,
} from "../../utils/apiUtils";
import { toRegulerCase } from "../../utils/Utils";
import TableSkeleton from "../../components/TableSkeleton";
import Nodata from "../../components/Nodata";
import { toast } from "react-toastify";
import { BorderRight } from "@mui/icons-material";

export default function Transfers({ hotelId }) {
  const [rowData, setRowData] = useState();

  const [columnDefs, setColumnDefs] = useState();

  const handleColumn = (data) => {
    const culumnData = Object.keys(data[0]).map((elm) => ({
      field: elm,
      headerName: toRegulerCase(elm),
    }));
    setColumnDefs(culumnData);
  };
  //---------------Skeleton--------
  const [table, setTable] = useState(false);
  const gettingTableData = () => {
    setTable(false);
    handleDjangoDataRequest(
      `api/v2/metaAdmin/metaGetTransfersForHotel/?hotel_id=${hotelId}&pg_name=RAZORPAY`
    )
      .then((data) => {
        setTable(true);
        handleColumn(data);
        setRowData(data);
      })
      .catch((err) => setTable(true));
  };
  useEffect(() => {
    gettingTableData();
  }, []);

  //------------Handleling Create Accound---------------

  const style = {
    position: "absolute",
    top: "40%",
    left: "45%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    borderRadius: "4px",
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [BookingId, setBookingId] = useState();
  const [pgPaymentId, setPgPaymentId] = useState();
  const [createPgTransfer, setCreatePgTransfer] = useState();
  const [amount, setAmount] = useState();
  const [transferNotes, setTransferNotes] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const handleSubmitButton = () => {
    if (!amount || !BookingId || !pgPaymentId) {
      toast.error("All fields are mandatory");
      return;
    }
    setSubmitLoader(true);
    handleSpringDataMutationRequest(
      "POST",
      "api/v2/metaAdmin/metaCreateManualTransferForHotel/",
      {
        hotel_id: hotelId,
        manual_transfer_amount: amount,
        manual_transfer_bookingid: BookingId,
        manual_transfer_pg_payment_id: pgPaymentId,
        transfer_notes: transferNotes,
        create_pg_transfer: createPgTransfer,
      }
    )
      .then((data) => {
        setSubmitLoader(false);
        toast.success(`Manual transfer created for ${BookingId}`);
        handleClose();
        gettingTableData();
      })
      .catch((err) => {
        console.log(err);
        setSubmitLoader(false);
        toast.error(err.response.data, {
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 5000,
          hideProgressBar: true,
        });
      });
  };

  return (
    <>
      <Button variant="custom-button" sx={{ mb: 2 }} onClick={handleOpen}>
        Create Manual Transfer
      </Button>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            Create Manual Transfer
          </Typography>
          <Divider light sx={{ mb: 2 }} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              id="outlined-basic"
              label="Booking ID"
              variant="outlined"
              value={BookingId}
              sx={{ width: "300px" }}
              size="small"
              onChange={(e) => setBookingId(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="PG payment ID to fund the transfer"
              variant="outlined"
              value={pgPaymentId}
              sx={{ width: "300px" }}
              size="small"
              onChange={(e) => setPgPaymentId(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              value={amount}
              sx={{ width: "300px" }}
              size="small"
              onChange={(e) => setAmount(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Transfer notes"
              variant="outlined"
              value={transferNotes}
              sx={{ width: "300px" }}
              size="small"
              onChange={(e) => setTransferNotes(e.target.value)}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <FormControl size="small" sx={{ width: "300px" }}>
              <InputLabel id="demo-simple-select-label">
                Create PG Transfer
              </InputLabel>

              <Select

                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={createPgTransfer}
                label="Create PG Transfer"
                onChange={(e) => setCreatePgTransfer(e.target.value)}

              >
                <MenuItem value={"YES"}>YES</MenuItem>
                <MenuItem value={"NO"}>NO</MenuItem>
              </Select>

            </FormControl>
          </Box>
          <Divider light sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              color="error"
              onClick={handleClose}
              sx={{ textTransform: "none" }}
            >
              Cancel
            </Button>
            <Button
              variant="custom-button"
              onClick={handleSubmitButton}
              disabled={submitLoader}
            >
              Submit {submitLoader && <CircularProgress size="0.9rem" />}
            </Button>
          </Box>
        </Box>
      </Modal>
      {table ? (
        rowData ? (
          <Table rowData={rowData} columnDefs={columnDefs} />
        ) : (
          <Nodata />
        )
      ) : (
        <TableSkeleton />
      )}
    </>
  );
}
