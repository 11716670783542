import React, { useState, useEffect } from "react";
import {
  Divider,
  Button,
  TextField,
  IconButton,
  Stack,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { default as ReactImageUploading } from "react-images-uploading";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import { format } from "date-fns";
import imageCompression from "browser-image-compression";
import {
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import { toast } from "react-toastify";

const CustomNotif = (props) => {
  const [logo, setlogo] = useState("");
  const [loader, setloader] = useState(false);
  const [tag, settag] = useState("");
  const [header, setheader] = useState("");
  const [message, setmessage] = useState("");
  const [buttonName, setbuttonName] = useState("");
  const [buttonLink, setbuttonLink] = useState("");
  const [status, setstatus] = useState("INACTIVE");
  const [selectedCountry, setselectedCountry] = useState("india");
  const [mockLoader, setmockLoader] = useState(false);

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = () => {
    setmockLoader(true);
    handleSpringDataRequest(`core/api/v1/customNotif/get`)
      .then((res) => {
        if (res.length > 0) {
          settag(res[0].tag);
          setheader(res[0].header);
          setmessage(res[0].message);
          setlogo([{ data_url: res[0].image }]);
          setbuttonName(res[0].button_name);
          setbuttonLink(res[0].button_link);
          setstatus(res[0].status);
          setselectedCountry(res[0].country);
        } else {
          settag("");
          setheader("");
          setmessage("");
          setbuttonName("");
          setbuttonLink("");
          setlogo([]);
          setstatus("INACTIVE");
          setselectedCountry("india");
        }
        setmockLoader(false);
      })
      .catch((err) => {
        toast.error("Something went wrong");
        setmockLoader(false);
      });
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.log("Image compression error:", error);
      return imageFile;
    }
  };

  const onChangeLogo = async (imageList) => {
    if (imageList.length > 0) {
      const originalFile = imageList[0].file;

      // Compress the image
      const compressedFile = await compressImage(originalFile);

      // Convert compressed image to base64
      const compressedBase64 = await imageCompression.getDataUrlFromFile(
        compressedFile
      );

      setlogo([{ data_url: compressedBase64 }]);
    } else {
      setlogo([]);
    }
  };

  const save = () => {
    if (header.trim().length <= 0) {
      return toast.error("Please enter header text");
    }
    if (tag.trim().length <= 0) {
      return toast.error("Please enter tag text");
    }
    if (message.trim().length <= 0) {
      return toast.error("Please enter message text");
    }
    setloader(true);
    const payload = {
      tag: tag,
      header: header,
      message: message,
      image: logo.length > 0 ? logo[0].data_url : "",
      buttonName: buttonName,
      buttonLink: buttonLink,
      country: selectedCountry,
      status: status,
      id: 1,
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/customNotif/create-update`,
      payload
    )
      .then((data) => {
        toast.success("Operation Successful");
        getAllData();
        setloader(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setloader(false);
      })
      .finally(() => {
        setloader(false);
      });
  };

  return (
    <div style={{ marginTop: "1rem", marginLeft: "2rem", marginRight: "2rem" }}>
      <div>
        <h2>Custom notification for pms</h2>
        <p>View and configure custom notification for pms</p>
        <Divider />
        <div className="mt-4">
          <h5>Tag text:</h5>
          <TextField
            label={"Tag"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter tag text here"}
            onChange={(e) => settag(e.target.value)}
            value={tag}
            name="schedule_name"
            id="schedule_name"
          />
        </div>
        <div className="mt-4">
          <h5>Header text:</h5>
          <TextField
            label={"Header"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter header text here"}
            onChange={(e) => setheader(e.target.value)}
            value={header}
            name="schedule_name"
            id="schedule_name"
          />
        </div>
        <div className="mt-4">
          <h5>Message text:</h5>
          <TextField
            label={"Message"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter message text here"}
            onChange={(e) => setmessage(e.target.value)}
            value={message}
            name="schedule_name"
            id="schedule_name"
          />
        </div>
        <div className="mt-4">
          <h5>Button name:</h5>
          <TextField
            label={"Button name"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter button name here"}
            onChange={(e) => setbuttonName(e.target.value)}
            value={buttonName}
            name="schedule_name"
            id="schedule_name"
          />
        </div>
        <div className="mt-4">
          <h5>Button link:</h5>
          <TextField
            label={"Button link"}
            className="input-lg w-50 mt-3"
            placeholder={"Enter button link here"}
            onChange={(e) => setbuttonLink(e.target.value)}
            value={buttonLink}
            name="schedule_name"
            id="schedule_name"
          />
        </div>

        <div className="mt-4">
          <h5>Select country for you want to be active:</h5>
          <FormControl style={{ width: "50%" }} className="mt-3">
            <InputLabel id="feature-select-label">Country</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              value={selectedCountry}
              label="Country"
              onChange={(e) => setselectedCountry(e.target.value)}
            >
              <MenuItem key={"india"} value={"india"}>
                {"INDIA"}
              </MenuItem>
              <MenuItem key={"united states"} value={"united states"}>
                {"UNITED STATES"}
              </MenuItem>
              <MenuItem key={"all"} value={"all"}>
                {"ALL"}
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="mt-4">
          <h5>Set notification status:</h5>
          <FormControl style={{ width: "50%" }} className="mt-3">
            <InputLabel id="feature-select-label">Status</InputLabel>
            <Select
              labelId="feature-select-label"
              id="feature-select"
              value={status}
              label="Status"
              onChange={(e) => setstatus(e.target.value)}
            >
              <MenuItem key={"ACTIVE"} value={"ACTIVE"}>
                {"ACTIVE"}
              </MenuItem>
              <MenuItem key={"INACTIVE"} value={"INACTIVE"}>
                {"INACTIVE"}
              </MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className="d-flex flex-column mr-4 mt-4 mb-3 w-50">
          <h5 className="mb-3" style={{ marginRight: "23px" }}>
            {"Side image"}
          </h5>

          <ReactImageUploading
            value={logo}
            onChange={onChangeLogo}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <Stack
                // p="2"
                // bgColor="gray.50"
                // borderRadius="lg"
                className="upload__image-wrapper"
                sx={{
                  borderRadius: "10px",
                  height: "150px",
                  backgroundColor: "#d3d3d336",
                  padding: "1rem",
                }}
              >
                {imageList.length === 0 && (
                  <IconButton
                    children={<UploadIcon icon="bi:upload" htmlColor="black" />}
                    variant="outline"
                    height="100%"
                    sx={{
                      borderRadius: "20px",
                      backgroundColor: "#d3d3d3",
                    }}
                    onClick={onImageUpload}
                    {...dragProps}
                  ></IconButton>
                )}
                <Stack spacing="2">
                  {imageList.map((image, index) => (
                    <Stack
                      sx={{ position: "relative", padding: "0.5rem" }}
                      key={index}
                    >
                      <img
                        src={
                          image["data_url"].includes("base64")
                            ? image["data_url"]
                            : image["data_url"] +
                              "?lastmod=" +
                              format(new Date(), "ddMMyyyyhhmmsstt")
                        }
                        style={{
                          height: "125px",
                          borderRadius: "1rem",
                          boxShadow: "md",
                          border: "1px solid",
                          borderColor: "gray.200",
                        }}
                        alt=""
                      />

                      <IconButton
                        children={<CloseIcon fontSize="xs" htmlColor="black" />}
                        size="xs"
                        sx={{
                          position: "absolute",
                          borderRadius: "100%",
                          backgroundColor: "white",
                          top: "0",
                          // colorScheme="teal"
                          marginTop: "0px !important",
                          left: "0",
                          "&:hover": {
                            color: "black",
                            backgroundColor: "#ffffffcc",
                          },
                        }}
                        onClick={() => {
                          onImageRemove(index);
                        }}
                      />
                      <IconButton
                        children={
                          <ChangeCircleIcon fontSize="xs" htmlColor="black" />
                        }
                        size="xs"
                        sx={{
                          position: "absolute",
                          borderRadius: "100%",
                          top: "0",
                          backgroundColor: "white",
                          // colorScheme="teal"
                          marginTop: "0px !important",
                          right: "0",
                          "&:hover": {
                            color: "black",
                            backgroundColor: "#ffffffcc",
                          },
                        }}
                        onClick={() => onImageUpdate(index)}
                      />
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
          </ReactImageUploading>
        </div>
        <div className="w-100 d-flex" style={{ marginTop: "1rem" }}>
          <Button
            variant="custom-button"
            onClick={save}
            type="submit"
            disabled={loader}
            style={{ marginLeft: "95%" }}
          >
            {loader ? (
              <CircularProgress size={15} color="inherit" sx={{ ml: 1.5 }} />
            ) : (
              `${"Save"}`
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomNotif;
