import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Switch,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  handleDjangoDataRequest,
  handleSpringDataMutationRequest,
} from "../../utils/apiUtils";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateUser({ handleClose, selectedUser, setRefresh }) {
  console.log("selectedUser", selectedUser);
  const [access, setAccess] = useState({});
  const [id, setId] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(
    selectedUser.email ? false : true
  );
  const [loader, setLoader] = useState(false);

  const handleListOfAccess = (data) => {
    const myData = {};

    data?.map((elm) => {
      myData[elm.feature_name] = false;
    });
    if (selectedUser?.email) {
      setUserName(selectedUser.email);
      setId(selectedUser.id);
      Object.keys(selectedUser.accessmap).map(
        (elm) => selectedUser.accessmap[elm] && (myData[elm] = true)
      );
    }
    setAccess(myData);
  };
  useEffect(() => {
    handleDjangoDataRequest("api/v2/metaAdmin/metaAdminGetAllAccessControl")
      .then((data) => {
        handleListOfAccess(data?.meta_access_control);
      })
      .catch((err) => console.log(err));
  }, []);
  //---------------------------

  useEffect(() => {
    setPassword("");
    setConfirmPassword("");
  }, [showPassword]);
  //---------------------Handle Switch component------------------

  const handleSwitch = (elm) => {
    const tempObj = { ...access };
    tempObj[elm] = !tempObj[elm];
    setAccess(tempObj);
  };
  //-------------Handle Submit Button------------
  const handleSubmitButton = () => {
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password should same.");
      return;
    }
    const features = Object.keys(access).filter((elm) => access[elm]);
    if (features.length === 0) {
      toast.error("Please select minimum one feature");
      return;
    }
    const payload = {
      username: username,
      password: password,
      isMetaAdmin: "YES",
      featureSet: features,
    };
    let api;
    if (selectedUser?.email) {
      api = "user/metaAdmin/update";
    } else {
      api = "user/metaAdmin/save";
    }
    setLoader(true);
    handleSpringDataMutationRequest("POST", api, payload)
      .then((data) => {
        setLoader(false);
        toast.success("Operation Success");
        setRefresh(new Date());
        handleClose();
      })
      .catch((err) => {
        setLoader(false);
        if (err?.response?.data) {
          toast.error(err?.response?.data);
        } else {
          toast.error("Something went wrong, try again later.");
        }
      });
  };
  return (
    <Box>
      {/* Login Credentials Section */}
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Card
        sx={{
          mb: 3,
          boxShadow: "none",
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
        }}
      >
        <CardContent>
          {/* Title */}
          <Typography variant="h6" fontWeight="600" gutterBottom>
            Login Credentials
          </Typography>
          {/* Subtitle */}
          <Typography sx={{ color: "gray", fontSize: "14px", mb: 3 }}>
            Configure the login details for your user.
          </Typography>
          <Divider sx={{ mb: 4 }} />

          {/* Toggle Switch for Additional Information */}
          {selectedUser?.email && (
            <Grid container alignItems="center" sx={{ mb: 3 }}>
              <Grid item xs={8}>
                <Typography sx={{ fontWeight: "500", fontSize: "14px" }}>
                  Would you like to update the password?
                </Typography>
                <Typography sx={{ color: "gray", fontSize: "13px" }}>
                  Toggle the switch to enable password update.
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={showPassword}
                  onChange={(e) => setShowPassword(e.target.checked)}
                />
              </Grid>
            </Grid>
          )}

          {/* Input Fields */}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                disabled={selectedUser?.email}
                size="small"
                id="outlined-username"
                label="Username (Enter a valid email address)"
                variant="outlined"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                InputProps={{
                  sx: { borderRadius: 1 },
                }}
              />
            </Grid>
            {showPassword && (
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  size="small"
                  id="outlined-password"
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    sx: { borderRadius: 1 },
                  }}
                />
              </Grid>
            )}
            {showPassword && (
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  type="password"
                  size="small"
                  id="outlined-confirm-password"
                  label="Confirm Password"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  InputProps={{
                    sx: { borderRadius: 1 },
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* Role Assignment Section */}
      <Card
        sx={{ boxShadow: "none", borderRadius: 2, backgroundColor: "#f9f9f9" }}
      >
        <CardContent>
          <Typography variant="h6" fontWeight="bold">
            Update/Assign Roles
          </Typography>
          <Typography sx={{ fontSize: "14px", color: "gray", mb: 2 }}>
            Select the pages that you want this user to be able to access. For
            example, AccountsDash staff would just need "AccountsDash".
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Box>
            <Grid container spacing={2}>
              {access &&
                Object.keys(access).map((elm) => (
                  <Grid item xs={6} key={elm}>
                    <Grid
                      container
                      alignItems="center"
                      sx={{
                        border: "1px solid #e0e0e0",
                        borderRadius: 1,
                        p: 1,
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <Grid item xs={10}>
                        <Typography sx={{ fontSize: "13px" }}>{elm}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Switch
                          checked={access[elm]}
                          onClick={() => handleSwitch(elm)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
            </Grid>

            {/* Buttons */}
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button
                variant="outlined"
                color="error"
                sx={{ mr: 2 }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitButton}
                disabled={loader}
              >
                Submit{" "}
                {loader && (
                  <CircularProgress
                    size={15}
                    color="inherit"
                    sx={{ ml: 1.5 }}
                  />
                )}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
