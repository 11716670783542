import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import CreateUser from "./access/CreateUser";
import {
  handleDjangoDataRequest,
  handleSpringDataMutationRequest,
} from "../utils/apiUtils";
import { toast } from "react-toastify";

export default function AccesControl() {
  const [refresh, setRefresh] = useState();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    handleDjangoDataRequest("api/v2/metaAdmin/metaAdminAccessControl/")
      .then((data) =>
        setUsers(
          data.allUsers.sort(function (a, b) {
            return a.email.localeCompare(b.email);
          })
        )
      )
      .catch((err) => console.log(err));
  }, [refresh]);
  //-------------handle Edit User-------------
  const [selectedUser, setSelectedUser] = useState({});
  const handleEdituser = (elm) => {
    setSelectedUser(elm);
    handleOpen();
  };
  //-----------handleAddNewUser-----------
  const handleAddNewUser = () => {
    setSelectedUser({});
    handleOpen();
  };
  //----------------------Modal------------
  const style = {
    position: "absolute",
    top: "0%",
    right: "0%",
    // transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  //------------------Modal Delete------------------------------
  const [selectedEmail, setSelectedEmail] = useState();
  const delstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "8px",
  };
  const [delopen, setDelOpen] = useState(false);
  const handleDelClose = () => {
    setDelOpen(false);
  };
  const handleDelOpen = () => {
    setDelOpen(true);
  };
  const handleDelIcon = (email) => {
    setSelectedEmail(email);
    handleDelOpen();
  };

  const handleDeleteUser = () => {
    handleSpringDataMutationRequest("POST", "user/deleteMetaAdmin", {
      username: selectedEmail,
    })
      .then((data) => {
        toast.success("User Successfully Removed");
        setRefresh(new Date());
        handleDelClose();
      })
      .catch((err) => toast.error("Something went wrong"));
  };
  //---------------------------------

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 4,
          pl: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Typography sx={{ fontSize: "1.5rem" }}>Access Control</Typography>
        <Button variant="custom-button" onClick={handleAddNewUser}>
          Add New User
        </Button>
      </Box>
      <Divider />

      <Box sx={{ height: "100vh", overflow: "scroll", p: 2 }}>
        {users.map((elm) => (
          <Card
            sx={{
              mb: 2,
              borderRadius: 2,
              boxShadow: 1,
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 1,
                }}
              >
                {/* Email */}
                <Typography
                  variant="body1"
                  fontWeight="500"
                  sx={{ color: "text.primary" }}
                >
                  {elm.email}
                </Typography>

                {/* Action Buttons */}
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Tooltip title="Edit" placement="top">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => handleEdituser(elm)}
                    >
                      <BorderColorIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete" placement="top">
                    <IconButton
                      size="small"
                      color="error"
                      onClick={() => handleDelIcon(elm.email)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {/* Divider */}
              <Divider sx={{ my: 2 }} />

              {/* Access Map */}
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  p: 1,
                }}
              >
                {Object.keys(elm.accessmap)
                  .filter((data) => elm.accessmap[data] === true)
                  .sort((a, b) => a.localeCompare(b))
                  .map((ell) => (
                    <Chip
                      key={ell}
                      label={ell}
                      variant="outlined"
                      size="small"
                      sx={{
                        bgcolor: "background.paper",
                        color: "text.secondary",
                        borderRadius: 1,
                        boxShadow: 1,
                        "&:hover": {
                          boxShadow: 3,
                          cursor: "pointer",
                        },
                      }}
                    />
                  ))}
              </Box>
            </CardContent>
          </Card>
        ))}
        <Drawer
          anchor="right"
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              width: 800,
              p: 2,
              boxShadow: 3,
            },
          }}
        >
          <Box sx={{ position: "relative", height: "100%" }}>
            <CreateUser
              handleClose={handleClose}
              selectedUser={selectedUser}
              setRefresh={setRefresh}
            />
          </Box>
        </Drawer>
        {/* -----------------------------------------Delete Modal----------------- */}
        <Modal
          open={delopen}
          onClose={handleDelClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              ...delstyle,
              p: 4,
              borderRadius: 2,
              width: 400,
              boxShadow: 24,
              bgcolor: "background.paper",
              position: "relative",
            }}
          >
            {/* Close Icon */}
            <CloseIcon
              fontSize="small"
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                cursor: "pointer",
                color: "gray",
              }}
              onClick={handleDelClose}
            />

            {/* Title */}
            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight="600"
              align="center"
              sx={{ mb: 2 }}
            >
              Confirm User Deletion
            </Typography>

            <Divider sx={{ mb: 3 }} />

            {/* Description */}
            <Typography
              id="modal-modal-description"
              align="center"
              sx={{ color: "gray", mb: 4 }}
            >
              You are about to delete the user. Do you want to proceed?
            </Typography>

            {/* Buttons */}
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                color="error"
                variant="outlined"
                sx={{ minWidth: 100 }}
                onClick={handleDelClose}
              >
                No
              </Button>
              <Button
                variant="custom-button"
                sx={{
                  minWidth: 100,
                  bgcolor: "primary.main",
                  color: "white",
                  "&:hover": { bgcolor: "primary.dark" },
                }}
                onClick={handleDeleteUser}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
}
