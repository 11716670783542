import axios from "axios";
import {
  BASE_DJANGO_ENDPOINT,
  BASE_SPRING_ENDPOINT,
  CM_PULL_BOOKING_ENDPOINT,
} from "./config";

// get data (spring api)
export const handleSpringDataRequest = async (
  resourceUrl,
  isProtected = true
) => {
  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    Promise.reject(new Error("Token not present"));
  }

  try {
    const response = await axios({
      method: "GET",
      url: `${BASE_SPRING_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `${token}||metaAdmin`,
      },
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// data mutation (sping boot api)
export const handleSpringDataMutationRequest = async (
  method,
  resourceUrl,
  reqData,
  isProtected = true
) => {
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    return Promise.reject(new Error("Token is not present"));
  }

  try {
    const response = await axios({
      method: `${method}`,
      url: `${BASE_SPRING_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `${token}||metaAdmin`,
      },
      ...(reqData && { data: reqData }),
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// get data (django api)
export const handleDjangoDataRequest = async (
  resourceUrl,
  isProtected = true
) => {
  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    Promise.reject(new Error("Token not present"));
  }

  try {
    const response = await axios({
      method: "GET",
      url: `${BASE_DJANGO_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `${token}||metaAdmin`,
      },
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

// data mutation (django api)
export const handleDjangoDataMutationRequest = async (
  method,
  resourceUrl,
  reqData,
  isProtected = true
) => {
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    return Promise.reject(new Error("Token is not present"));
  }

  try {
    const response = await axios({
      method: `${method}`,
      url: `${BASE_DJANGO_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `${token}||metaAdmin`,
      },
      ...(reqData && { data: reqData }),
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

//data Mutation for Push Channel Only---------------

export const handleSpringDataMutationRequestCmBooking = async (
  method,
  resourceUrl,
  reqData,
  isProtected = true
) => {
  if (!["POST", "PUT", "DELETE", "PATCH"].includes(method)) {
    return Promise.reject(new Error("Pass a valid method"));
  }

  const token = localStorage.getItem("token");

  if (isProtected && !token) {
    return Promise.reject(new Error("Token is not present"));
  }

  try {
    const response = await axios({
      method: `${method}`,
      url: `${CM_PULL_BOOKING_ENDPOINT}${resourceUrl}`,
      headers: {
        "content-type": "application/json",
        Authorization: `${token}||metaAdmin`,
      },
      ...(reqData && { data: reqData }),
    });
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};
