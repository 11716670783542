import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import {
  handleDjangoDataRequest,
  handleDjangoDataMutationRequest,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../utils/apiUtils";
import { toast } from "react-toastify";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { Table } from "react-bootstrap";

import InfoIcon from "@mui/icons-material/Info";

export default function Features({
  hotelId,
  basicInfo,
  setRefresh,
  isIsha,
  accessControl,
  groupId,
  emailId,
}) {
  const [featuresArr, setFeaturesArr] = useState();
  const [allFeaturesArr, setAllFeaturesArr] = useState([]);
  const [featureObject, setFeatureObject] = useState();

  const [allRestrictions, setAllRestrictions] = useState(false);
  const [allFeatures, setAllFeatures] = useState(false);
  const [actionType, setActionType] = useState("features");

  const restrictions = [
    "HideBalanceDueWhatsappAccess",
    "CustomerInformationCaptureAndDisplay",
    "UploadIdToCheckin",
    "HideBlockRooms",
    "ResGridCustomerEmail",
    // "ResGridCustomerName",
    "ResGridCustomerPhone",
    "HideUnBlockRooms",
    "MandatoryEmail",
    "MandatoryContactNumber",
    "MandatoryAddress",
    "MandatoryZipcode",
    "DeleteFolio",
    "DeleteCashPaymentFolio",
    "TaxexemptonCheckOutBooking",
  ];

  const removedFeatures = [
    "user_access_map",
    "PCBEBasicConfig",
    "ConfigPrivileges",
  ];

  const ishaFeatures = [
    "EnquiryConfiguration",
    "EnquiryDashboard",
    "IshaEnquiry",
    "IshaExtraGuestDetails",
    "Tags",
  ];

  useEffect(() => {
    const features = basicInfo?.hotel?.hotel_feature_map;
    // console.log("features", basicInfo);
    setFeatureObject(features);
    const arr = Object.keys(features)
      .filter((elm) => !removedFeatures.includes(elm))
      .filter((itm) => (!isIsha ? !ishaFeatures.includes(itm) : true))
      .filter((item) =>
        item === "TallySync"
          ? basicInfo?.hotel?.hotel_country?.toLowerCase() === "india"
          : true
      )
      .sort();
    setFeaturesArr(arr);
    setAllFeaturesArr(arr);
  }, [basicInfo]);

  const handleSwitch = (elm) => {
    const newObject = { ...featureObject };
    newObject[elm] = !newObject[elm];
    setFeatureObject(newObject);
  };

  const handleLogoutUsers = () => {
    const arr = allFeaturesArr.filter((elm) => !featureObject[elm]);
    const offIdsArr = [];
    arr?.map((elm) => {
      offIdsArr?.push(accessControl?.[elm]?.id);
    });

    if (offIdsArr?.includes(241) || offIdsArr?.includes("241")) {
      handleMagicLink();
    }
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/accesscontrol/remove-subuser-access-from-meta?hotelId=${hotelId}&hotelemail=${emailId}&groupId=${groupId}`,
      offIdsArr
    )
      .then(() => {
        toast.success("successfully logged out all users");
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  const updateWhatsapp = (data) => {
    const payload = { ...data?.[0] };
    payload.earlyCheckinStatus = "DISABLED";
    payload.lateCheckoutStatus = "DISABLED";
    payload.selfCheckinStatus = "DISABLED";
    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/whatsapp/update?hotel_id=${hotelId}&hotelId=${hotelId}`,
      payload
    )
      .then(() => {})
      .catch(() => {});
  };

  const updateSelfService = (data) => {
    const payload = { ...data };
    payload.send_email = "NO";
    handleSpringDataMutationRequest(
      "POST",
      `api/v2/selfserviceconfig/saveSelfServiceConfig/?hotel_id=${hotelId}&hotelId=${hotelId}`,
      payload
    )
      .then(() => {})
      .catch(() => {});
  };

  const handleMagicLink = () => {
    handleSpringDataRequest(
      `core/api/v1/whatsapp/get-all?hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((data) => {
        if (data?.length > 0) {
          updateWhatsapp(data);
        }
      })
      .catch(() => {});

    handleDjangoDataRequest(
      `api/v2/selfserviceconfig/getSelfServiceConfig/?hotel_id=${hotelId}&hotelId=${hotelId}`
    )
      .then((data) => {
        if (data?.send_email === "YES") {
          updateSelfService(data);
        }
      })
      .catch(() => {});
  };
  const handleSubmitButton = () => {
    setLoader(true);
    const arr = allFeaturesArr.filter((elm) => featureObject[elm]);
    // console.log(arr);
    const payload = {
      hotel_id: hotelId,
      features: arr,
    };
    handleDjangoDataMutationRequest(
      "POST",
      "api/v2/metaAdmin/updateHotelFeatures/",
      payload
    )
      .then((data) => {
        handleLogoutUsers();
        setLoader(false);
        toast.success(data);
        setRefresh(new Date());
      })
      .catch((err) => {
        setLoader(false);
        // console.log(err);
        toast.error(err);
      });
  };
  //---------------Search-----------
  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    if (searchText && allFeaturesArr) {
      const arr = allFeaturesArr.filter((elm) =>
        accessControl[elm]?.display_name.toLowerCase().includes(searchText.toLowerCase())
      );
      setFeaturesArr(arr);
    } else {
      setFeaturesArr(allFeaturesArr);
    }
  }, [searchText, allFeaturesArr]);  
  //------------------Loader---------------------
  const [loader, setLoader] = useState(false);

  //----------------all switch handle------

  const handleAllSwitch = () => {
    let res = true;
    for (let i = 0; i < restrictions.length; i++) {
      if (!featureObject[restrictions[i]]) {
        res = false;
        break;
      }
    }
    setAllRestrictions(res);

    const arr = allFeaturesArr?.filter((item) => !restrictions.includes(item));
    let fer = true;
    for (let i = 0; i < arr.length; i++) {
      if (!featureObject[arr[i]]) {
        fer = false;
        break;
      }
    }
    setAllFeatures(fer);
  };
  useEffect(() => {
    if (allFeaturesArr.length > 0) {
      handleAllSwitch();
    }
  }, [allFeaturesArr, featureObject]);

  //-----------handeling all (restrictions  and features) switch options---------------------------

  const handleAllRestrictions = () => {
    setSearchText("");
    if (featureObject) {
      restrictions?.map((item) => {
        featureObject[item] = !allRestrictions;
      });
      setAllRestrictions((prev) => !prev);
    }
  };
  const handleAllFeatures = () => {
    setSearchText("");
    if (featureObject) {
      const arr = allFeaturesArr?.filter(
        (item) => !restrictions.includes(item)
      );
      arr?.map((item) => {
        featureObject[item] = !allFeatures;
      });
      setAllFeatures((prev) => !prev);
    }
  };
  //------------------------

  return (
    <Box>
      <Card sx={{}}>
        <CardHeader
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {/* <Typography variant="h5" sx={{ fontWeight: "medium" }}>
                Features and restrictions
              </Typography> */}
              <ButtonGroup>
                <Button
                  sx={{ width: "250px", textTransform: "none" }}
                  variant={
                    actionType === "features" ? "custom-button" : "outlined"
                  }
                  onClick={() => {
                    setActionType("features");
                    setSearchText("");
                  }}
                >
                  Features
                </Button>
                <Button
                  sx={{ width: "250px", textTransform: "none" }}
                  onClick={() => {
                    setActionType("restrictions");
                    setSearchText("");
                  }}
                  variant={
                    actionType === "restrictions" ? "custom-button" : "outlined"
                  }
                >
                  Restrictions
                </Button>
              </ButtonGroup>
              <Paper
                component="form"
                sx={{
                  // mt: 1,
                  // p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <InputBase
                  value={searchText}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Features and restrictions....."
                  inputProps={{
                    "aria-label": "Search Features and restrictions.....",
                  }}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
          }
        />

        <CardContent>
          {/* ---------------------------Restrictions------------------------------------- */}
          {actionType === "restrictions" && (
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "medium", mb: 2 }}>
                  Restrictions
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="d-flex flex-column w-100">
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "400px",
                        width: "100%",
                      }}
                    >
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Name</th>

                            <th>
                              {/* <Switch
                                checked={allRestrictions}
                                onChange={() => handleAllRestrictions()}
                              /> */}
                              All Restrictions Access
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {featuresArr
                            ?.filter((item) => restrictions.includes(item))

                            ?.map((elm) => (
                              <tr key={elm}>
                                <td>
                                  <span className="d-flex align-item-center">
                                    <p
                                      className="mr-1 mb-0"
                                      style={{ marginTop: "7px" }}
                                    >
                                      {accessControl[elm]?.display_name}
                                    </p>
                                    <Tooltip
                                      title={
                                        <Typography sx={{ fontSize: "18px" }}>
                                          {
                                            accessControl[elm]
                                              ?.display_description
                                          }
                                        </Typography>
                                      }
                                      placement="right"
                                    >
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                </td>
                                <td>
                                  <Switch
                                    checked={featureObject[elm]}
                                    onChange={() => handleSwitch(elm)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* ------------------------------Features------------------------------------------ */}
          {actionType === "features" && (
            <Grid container spacing={2} sx={{ p: 1 }}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ fontWeight: "medium", mb: 2 }}>
                  Features
                </Typography>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="d-flex flex-column w-100">
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "400px",
                        width: "100%",
                      }}
                    >
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Name</th>

                            <th>
                              <Switch
                                checked={allFeatures}
                                onChange={() => handleAllFeatures()}
                              />
                              All Features Access
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {featuresArr
                            ?.filter((item) => !restrictions.includes(item))

                            ?.map((elm) => (
                              <tr key={elm}>
                                <td>
                                  <span className="d-flex align-item-center">
                                    <p
                                      className="mr-1 mb-0"
                                      style={{ marginTop: "7px" }}
                                    >
                                      {accessControl[elm]?.display_name}
                                    </p>
                                    <Tooltip
                                      title={
                                        <Typography sx={{ fontSize: "18px" }}>
                                          {
                                            accessControl[elm]
                                              ?.display_description
                                          }
                                        </Typography>
                                      }
                                      placement="right"
                                    >
                                      <IconButton>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </span>
                                </td>
                                <td>
                                  <Switch
                                    checked={featureObject[elm]}
                                    onChange={() => handleSwitch(elm)}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
        </CardContent>

        <CardActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="custom-button"
            sx={{ mb: 2, width: "250px" }}
            onClick={handleSubmitButton}
          >
            Submit {loader && <CircularProgress size={24} />}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
